export enum ClassesEnum {
    Header = "Header",
    NavBar = "NavBar",
    NavItem = "NavItem",
    Button = "Button",
    EditButton = "Edit",
    Text = "Text",
    TextArea = "TextArea",
    Checkbox = "Checkbox",
    Card = "Card",
    FormField = "FormField",
    Input = "Input",
    CardInput = "CardInput",
    Link = "Link",
    Alert = "Alert",
    Select = "Select",
    Badge = "Badge",
    Menu = "Menu",
    Modal = "Modal",
    RadioButton = "Radio",
    StateContainer = "StateContainer",
    MenuItem = "MenuItem",
    Slider = "Slider",
    Drawer = "Drawer",
    UserInfoContainer = "UserInfoContainer",
    AgeGroupContainer = "AgeGroupContainer",
    GradeCurriculumContainer = "GradeCurriculumContainer",
    MembershipContainer = "MembershipContainer",
    PremiumServiceContainer = "PremiumServiceContainer",
    InternationalPremiumServicesContainer = "InternationalPremiumServicesContainer",
    PaymentDetailsContainer = "PaymentDetailsContainer",
    CardDetailsContainer = "CardDetailsContainer",
    StudentSelectionContainer = "StudentSelectionContainer",
    CreateNewStudentContainer = "CreateNewStudentContainer",
    CheckoutContainer = "CheckoutContainer",
    ExistingUserContainer = "ExistingUserContainer",
    GradeContainer = "GradeContainer",
    PathwayContainer = "PathwayContainer",
    PaymentSuccessContainer = "PaymentSuccessContainer",
    ChangePasswordContainer = "ChangePasswordContainer",
    DashboardContainer = "DashboardContainer",
    InitiateEnrolmentContainer = "InitiateEnrolmentContainer",
    ManageProgramsContainer = "ManageProgramsContainer",
    ManageStudentContainer = "ManageStudentContainer",
    StudentDocsContainer = "StudentDocsContainer",
    SharedAccessContainer = "SharedAccessContainer",
    AccountSetupContainer = "AccountSetupContainer",
    AccountDetailsContainer = "AccountDetailsContainer",
    BillingInfoContainer = "BillingInfoContainer",
    AccountSuccessContainer = "AccountSuccessContainer",
    CreatePasswordContainer = "CreatePasswordContainer",
    CreateStudentContainer = "CreateStudentContainer",
    EnrolmentInfoContainer = "EnrolmentInfoContainer",
    EnrolmentSuccessContainer = "EnrolmentSuccessContainer",
    SelectProgramContainer = "SelectProgramContainer",
    StudentPerformanceContainer = "StudentPerformanceContainer",
    LoginContainer = "LoginContainer",
    SubjectEvaluationContainer = "SubjectEvaluationContainer",
    BillingAddressContainer = "BillingAddressContainer",
    SubmittedForReviewContainer = "SubmittedForReviewContainer",
    SubjectSelectionContainer = "SubjectSelectionContainer",
    FooterInfo = "FooterInfo",
    ExternalCourseManager = "ExternalCourseManager",
    BrowseElectivesContainer = "BrowseElectivesContainer",
    SelectElectivesContainer = "SelectElectivesContainer",
    ElectiveIntroContainer = "ElectiveIntroContainer",
    ElectiveRecommendationContainer = "ElectiveRecommendationContainer",
    LocationSelectDrawer = "LocationSelectDrawer",
    Option = "Option",
    InitialRegistrationContainer = "InitialRegistrationContainer",
    BeginRegistrationContainer = "BeginRegistrationContainer",
    ManageRegistrationServiceContainer = "ManageRegistrationServiceContainer",
    RegistrationStepsContainer = "RegistrationStepsContainer",
    RegistrationDetailContainer = "RegistrationDetailContainer",
    ReportsContainer = "ReportsContainer",
    ManageElectivesContainer = "ManageElectivesContainer",
    ManageSubjectsContainer = "ManageSubjectsContainer"
}
